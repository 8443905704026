import React from "react";
import { graphql, Link, PageProps, useStaticQuery } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Section from "../components/Section";
import { MDXRenderer } from "gatsby-plugin-mdx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 700,
            margin: "0 auto",
            paddingBottom: 60,
        },
        md: {},
    })
);

interface AboutMarkdownQuery {
    mdx: {
        body: string;
    };
}

const description = "Read this page to learn more about Tim Edgar.";

const About: React.FC<PageProps> = (props) => {
    const classes = useStyles();

    const data = useStaticQuery<AboutMarkdownQuery>(
        graphql`
            query {
                mdx(frontmatter: { type: { eq: "about" } }) {
                    body
                }
            }
        `
    );
    const body = data.mdx.body;
    console.log(props.path);
    return (
        <Layout section="about">
            <SEO title="About" description={description} path={props.path} />
            <Section content first last>
                <PageTitle title="About Me" />

                <section className={classes.md}>
                    <MDXRenderer>{body}</MDXRenderer>
                </section>
            </Section>
        </Layout>
    );
};

export default About;
